<template>
  <b-card 
  no-body
  class="mb-0">
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>{{ $t('Table.Categories.Title') }}</label>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" :to="{ name: 'setups-category-create' }">
              <span class="text-nowrap">{{ $t('Create') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table responsive="sm" :fields="fields" :items="items">
      <!-- Column: Actions -->
      <template #cell(id)="data">
        <div class="text-nowrap">
          <feather-icon
            @click="update(data.item.id)"
            icon="EditIcon"
            class="cursor-pointer"
            size="20"
          />
          <b-tooltip
            title="Sunting"
            class="cursor-pointer"
          />

          <feather-icon
            @click="showMsgBoxRemove(data.item.id)"
            icon="TrashIcon"
            size="20"
            class="mx-2 cursor-pointer"
          />
          <b-tooltip
            title="Hapus"
            class="cursor-pointer"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing 1 to 10 of 50 entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            :value="1"
            :total-rows="10"
            :per-page="pageLength"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BTable, BButton, BPagination,} from 'bootstrap-vue'
import moduleApi from '@/store/module'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard, 
    BRow, 
    BCol,
    BTable,
    BButton,
    BPagination,
  },
  data() {
    return {
      pageLength: 10,
      fields: [
        { key: 'title', label: 'Nama' },
        { key: 'description', label: 'Deskripsi' },
        { key: 'id', label: 'Tindakan' },
      ],
      items: [],
      idInstansi: '',
    }
  },
  methods: {
    fetchDataListCategories() { 
      moduleApi.fetchDataCategories(this.idInstansi)
        .then(response => {
            this.items  = response.data.payload
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
    update (id) {
      this.$router.replace({name: 'setups-category-create', params: { id: id }})
    },
    showMsgBoxRemove(id) {
      this.$bvModal
        .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.remove(id)
          }
        })
    },
    remove (id) {
      moduleApi.deletedCategories(id)
        .then( response => {
          this.fetchDataListCategories()
        })
        .catch( error => {
          console.log(error)
        })
    },
  },
  created () {
    const userData = getUserData()
    this.idInstansi = userData.idInstansi

    this.fetchDataListCategories()
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>